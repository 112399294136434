/* ==========================================================================
   #MODAL
   ========================================================================== */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
}

/* ##MODAL WINDOW
   ========================================================================== */
.modal-window {
  position: relative;
  width: 100%;
  max-width: 640px;
  padding: 2.5rem;
  border-radius: 12px;
  background-color: #fff;
}

/* ##MODAL CLOSE
   ========================================================================== */
.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 24px;
  height: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  transition: color 0.3s ease-out;
}

.modal-close:hover {
  color: #487ee4;
}

.modal-close:focus {
  outline: none;
}
